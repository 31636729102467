import React from 'react';
import styles from './styles.module.css';

import { ArrowButton } from 'src/components/ArrowButton';

import { FigureOption } from './components/FigureOption';
import { EmbeddedFiguresQuestionSpec } from '../../index';
import { Tooltip } from 'src/components/Tooltip';

interface Props {
	currQuestionNum: number;
	questionTotal: number;
	nextQuestion: (answer?: string | undefined) => void;
	currQuestionSpec: EmbeddedFiguresQuestionSpec;
}

interface State {
	currSelectedAnswer: null | number;
	checked: Array<boolean>;
	anyChecked: boolean;
}

export class Questions extends React.Component<Props, State> {
	state: State = {
		currSelectedAnswer: null,
		checked: [false],
		anyChecked: false,
	};
	selectAnswer = (figureId: number) => {
		let checked = [...this.state.checked];
		if (this.props.currQuestionSpec.isMultiSelect) checked[figureId - 1] = !checked[figureId - 1];
		this.setState({ checked, anyChecked: checked.includes(true), currSelectedAnswer: figureId });
	};

	render() {
		const figureX = require(`./lineImgs/${this.props.currQuestionSpec.embeddedFigureX}.svg`);
		const figureA = require(`./lineImgs/${this.props.currQuestionSpec.possibleFigures[0].figureImg}.svg`);
		const figureB = require(`./lineImgs/${this.props.currQuestionSpec.possibleFigures[1].figureImg}.svg`);
		const figureC = require(`./lineImgs/${this.props.currQuestionSpec.possibleFigures[2].figureImg}.svg`);
		const figureD = require(`./lineImgs/${this.props.currQuestionSpec.possibleFigures[3].figureImg}.svg`);
		let figureE: string | undefined;
		let figureF: string | undefined;
		let figureG: string | undefined;
		let figureH: string | undefined;
		const skipTooltip = `Submits this question with no answer. The question cannot be attempted later.`;
		if (this.props.currQuestionSpec.possibleFigures.length > 4) {
			figureE = require(`./lineImgs/${this.props.currQuestionSpec.possibleFigures[4].figureImg}.svg`);
		}
		if (this.props.currQuestionSpec.possibleFigures.length > 5) {
			figureF = require(`./lineImgs/${this.props.currQuestionSpec.possibleFigures[5].figureImg}.svg`);
		}
		if (this.props.currQuestionSpec.possibleFigures.length > 6) {
			figureG = require(`./lineImgs/${this.props.currQuestionSpec.possibleFigures[6].figureImg}.svg`);
		}
		if (this.props.currQuestionSpec.possibleFigures.length > 7) {
			figureH = require(`./lineImgs/${this.props.currQuestionSpec.possibleFigures[7].figureImg}.svg`);
		}
		let counter: number;
		for (
			counter = this.state.checked.length;
			counter < this.props.currQuestionSpec.possibleFigures.length;
			counter++
		) {
			this.state.checked.push(false);
		}
		return (
			<>
				<div className={styles.figureX}>
					<img src={figureX} alt="Figure X" />
				</div>
				<div className={styles.figureText}>Figure X</div>
				<div className={styles.questionText}>
					Select all of the below pictures which are rotations of Figure X (see above):
				</div>
				<div className={styles.answerContainer}>
					<FigureOption
						figureId={1}
						selectAnswer={this.selectAnswer}
						isSelected={
							this.props.currQuestionSpec.isMultiSelect
								? this.state.checked[0]
								: this.state.currSelectedAnswer === 1
						}
					>
						<img src={figureA} alt="Figure 1" />
					</FigureOption>
					<FigureOption
						figureId={2}
						selectAnswer={this.selectAnswer}
						isSelected={
							this.props.currQuestionSpec.isMultiSelect
								? this.state.checked[1]
								: this.state.currSelectedAnswer === 2
						}
					>
						<img src={figureB} alt="Figure 2" />
					</FigureOption>
					<FigureOption
						figureId={3}
						selectAnswer={this.selectAnswer}
						isSelected={
							this.props.currQuestionSpec.isMultiSelect
								? this.state.checked[2]
								: this.state.currSelectedAnswer === 3
						}
					>
						<img src={figureC} alt="Figure 3" />
					</FigureOption>
					<FigureOption
						figureId={4}
						selectAnswer={this.selectAnswer}
						isSelected={
							this.props.currQuestionSpec.isMultiSelect
								? this.state.checked[3]
								: this.state.currSelectedAnswer === 4
						}
					>
						<img src={figureD} alt="Figure 4" />
					</FigureOption>
				</div>
				<div className={styles.answerContainer}>
					{this.props.currQuestionSpec.possibleFigures.length > 4 && (
						<FigureOption
							figureId={5}
							selectAnswer={this.selectAnswer}
							isSelected={
								this.props.currQuestionSpec.isMultiSelect
									? this.state.checked[4]
									: this.state.currSelectedAnswer === 5
							}
						>
							<img src={figureE} alt="Figure 5" />
						</FigureOption>
					)}
					{this.props.currQuestionSpec.possibleFigures.length > 5 && (
						<FigureOption
							figureId={6}
							selectAnswer={this.selectAnswer}
							isSelected={
								this.props.currQuestionSpec.isMultiSelect
									? this.state.checked[5]
									: this.state.currSelectedAnswer === 6
							}
						>
							<img src={figureF} alt="Figure 6" />
						</FigureOption>
					)}
					{this.props.currQuestionSpec.possibleFigures.length > 6 && (
						<FigureOption
							figureId={7}
							selectAnswer={this.selectAnswer}
							isSelected={
								this.props.currQuestionSpec.isMultiSelect
									? this.state.checked[6]
									: this.state.currSelectedAnswer === 7
							}
						>
							<img src={figureG} alt="Figure 7" />
						</FigureOption>
					)}
					{this.props.currQuestionSpec.possibleFigures.length > 7 && (
						<FigureOption
							figureId={8}
							selectAnswer={this.selectAnswer}
							isSelected={
								this.props.currQuestionSpec.isMultiSelect
									? this.state.checked[7]
									: this.state.currSelectedAnswer === 8
							}
						>
							<img src={figureH} alt="Figure 8" />
						</FigureOption>
					)}
				</div>
				<div className={styles.submitContainer}>
					<ArrowButton
						caption="Submit Answer"
						onClick={() => {
							this.state.currSelectedAnswer &&
								this.props.currQuestionSpec.isMultiSelect &&
								this.props.nextQuestion(JSON.stringify(this.state.checked));
							this.state.currSelectedAnswer &&
								!this.props.currQuestionSpec.isMultiSelect &&
								this.props.nextQuestion(this.state.currSelectedAnswer.toString());
							this.setState({ checked: [], anyChecked: false, currSelectedAnswer: null });
						}}
						isDisabled={
							this.props.currQuestionSpec.isMultiSelect
								? !this.state.anyChecked
								: this.state.currSelectedAnswer === null
						}
					/>
					<Tooltip caption={skipTooltip}>
						<button
							className={styles.skipButton}
							onClick={() => {
								this.setState({ checked: [], anyChecked: false, currSelectedAnswer: null });
								this.props.nextQuestion();
							}}
						>
							{`Skip >`}
						</button>
					</Tooltip>
				</div>
			</>
		);
	}
}
