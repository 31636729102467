import React, { Fragment } from 'react';
import classNames from 'classnames';

import styles from './styles.module.css';

interface Props {
	isSelected: boolean;
	figureId: number;
	selectAnswer: (arg0: number) => any;
	// TODO: determine type: SVG?
	children: any;
}

export const FigureOption = (props: Props) => {
	return (
		<button
			className={classNames(styles.figureContainer, {
				[styles.selectedAnswer]: props.isSelected,
			})}
			onClick={() => {
				return props.selectAnswer(props.figureId);
			}}
		>
			{props.children}
		</button>
	);
};
